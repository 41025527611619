<template>
    <el-dialog width="720px" title="批次轉換通行證(Token)格式" :visible="dialogVisible" @close="dialogDataOnClose()" style="padding-top: 10px;">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;" v-if="serviceList.length > 0">
                <span class="data-label" style="vertical-align: bottom; height: 38px;">應用服務營運商(EMSP) </span>
                <el-select v-model="updateQuery.serviceId"  size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length > 0">
                    <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
                </el-select>
            </el-col>
        </el-row>
        <el-divider content-position="left">用戶與RFID轉換通行證的統計資訊: </el-divider>
        <div v-loading="isLoading" >
        <el-row :gutter="20">
            <el-col :span="1" >
                &nbsp;
            </el-col>
            <el-col :span="6" >
                營運商會員/卡片數量
            </el-col>
            <el-col :span="6" >
                OCPI Token 通行證數量
            </el-col>
            <el-col :span="6" >
                尚未轉換通行證數量
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="1" style="padding-top: 22px;">會員</el-col>
            <el-col :span="6">
                <div class="statistic-card" style="background-color:rgb(235.9, 245.3, 255);">
                    <el-statistic title="會員數量" :value="statisticsData.cntApp" >
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                會員數量
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="statistic-card" style="background-color:rgb(252.5, 245.7, 235.5);">
                    <el-statistic title="會員通行證數量" :value="statisticsData.cntAppUserToken" >
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                會員通行證數量
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="statistic-card" style="background-color:rgb(254, 240.3, 240.3);">
                    <el-statistic title="尚未轉換數量" :value="statisticsData.cntNotYetAppUserToken" >
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                尚未轉換數量
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="5">
                <div class="statistic-card">
                    <el-button @click="batchConversion('app')">批次轉換</el-button>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="1" style="padding-top: 22px;">卡片</el-col>
            <el-col :span="6">
                <div class="statistic-card" style="background-color:rgb(235.9, 245.3, 255);">
                    <el-statistic title="卡片數量" :value="statisticsData.cntRfid" >
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                卡片數量
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="statistic-card" style="background-color:rgb(252.5, 245.7, 235.5);">
                    <el-statistic title="卡片通行證數量" :value="statisticsData.cntRfidToken" >
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                卡片通行證數量
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="statistic-card" style="background-color:rgb(254, 240.3, 240.3);">
                    <el-statistic title="尚未轉換數量" :value="statisticsData.cntNotYetRfidToken" >
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                尚未轉換數量
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="5">
                <div class="statistic-card">
                    <el-button v-loading="isBatchRfidProcessing" @click="batchConversion('rfid')">批次轉換</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
        <div class="dialog-footer" style="text-align: center; margin-top: 26px;">
            <el-button @click="dialogDataOnClose()">關閉</el-button>
        </div>
    </el-dialog>
</template>

<script>

import { mixins } from '@/views/common/mixins.js';
export default {
    name: 'TokenBatchSetting',
    
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        serviceList: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogVisible: {
            require: true,
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            isLoading: true,
            isBatchRfidProcessing: false,
            statisticsData: {
                cntApp: undefined,
            },
            updateQuery: {
                serviceId: this.$store.state.currentAccount.serviceInfo.id
            },
            saveDisabled: true
        };
    },
    watch: {
        updateQuery: {
            handler(oldVal, newVal) {                
                this.statistics(newVal.serviceId)
            },
            deep: true
        }
    },
    
    created() {
       this.statistics(this.$store.state.currentAccount.serviceInfo.id)
    },
    methods: {
        dialogDataOnClose() {
            this.$emit('close') // 觸發父層 close
        },
        statistics(id){
            if(id != undefined) {
                this.isLoading = true
                this.axios.post('/api/v1/ocpi/token/statistics?serviceId='+this.updateQuery.serviceId).then(res => {
                    this.statisticsData = res.data
                    this.isLoading = false
                })
            }
        },
        batchConversion(type) {
            this.isBatchRfidProcessing = true;
            this.axios.post('/api/v1/ocpi/token/batch/'+type+'?serviceId='+this.updateQuery.serviceId).then(res => {
                    console.log(res.data)
                    this.isBatchRfidProcessing = false
                    this.statistics(this.updateQuery.serviceId)
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.data-label {
  width: 160px; display: inline-block;

}

.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}

.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  height: 100%;
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  //background-color: rgb(235.9, 245.3, 255);
}
</style>